import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const CREATE_ENQUIRE_REQUEST = "CREATE_ENQUIRE_REQUEST";
export const CREATE_ENQUIRE_SUCCESS = "CREATE_ENQUIRE_SUCCESS";
export const CREATE_ENQUIRE_FAILURE = "CREATE_ENQUIRE_FAILURE";

export const createEnquireForm = (data,clearData) => {
    return async (dispatch) => {
      try {
        dispatch({ type: CREATE_ENQUIRE_REQUEST });
        const url = `${BASE_URL}/enquire/submit/enquire/v1`;
        const response = await axios.post(url, data,{
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        dispatch({ type: CREATE_ENQUIRE_SUCCESS, payload: response.data });
        clearData()
        toast.success(response.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } catch (error) {
        console.error(error);
        dispatch({ type: CREATE_ENQUIRE_FAILURE, payload: error.message });
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
  };