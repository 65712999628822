import * as subjectAction from "./subjectAction";

export const subjectFeatureKey = "subjectInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  subjects: [],
  subjectDetails: {},
  completedSubjects: [],
  overAllProgress: 0,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case subjectAction.GET_SUBJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        subjects: payload.data,
      };
    case subjectAction.GET_SUBJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subjects: [],
      };
    case subjectAction.GET_MY_COMPLETED_SUBJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.GET_MY_COMPLETED_SUBJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        completedSubjects: payload?.data,
      };
    case subjectAction.GET_MY_COMPLETED_SUBJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        completedSubjects: [],
      };
    case subjectAction.COUNT_SUBJECT_OVERALL_PROGRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.COUNT_SUBJECT_OVERALL_PROGRESS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subjectAction.COUNT_SUBJECT_OVERALL_PROGRESS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case subjectAction.GET_OVERALL_PROGRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subjectAction.GET_OVERALL_PROGRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        overAllProgress: payload.data,
      };
    case subjectAction.GET_OVERALL_PROGRESS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        overAllProgress: 0,
      };
    default:
      return state;
  }
};
