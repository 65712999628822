import * as enquireAction from "./enquireAction";

export const enquireFeatureKey = "enquireInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  enquire: [],
  enquireDetails: {},
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case enquireAction.CREATE_ENQUIRE_REQUEST:
        return {
            ...state,
            loading:true
        }
    case enquireAction.CREATE_ENQUIRE_SUCCESS:
        return {
            ...state,
            loading:false
        }
    case enquireAction.CREATE_ENQUIRE_FAILURE:
        return {
            ...state,
            loading:false,
            errorMsg:payload
        }
    default:
      return state;
  }
};
