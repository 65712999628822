import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as userAction from "../../redux/user/userAction";
import { useDispatch } from "react-redux";
import brand_logo from "../../assets/svg/brand_logo.svg";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSendEmail = (e) => {
    e.preventDefault();
    dispatch(userAction.forgotPassword({ email }));
  };

  const handleGoBack = () => {
    navigate(-1); // Navigates back
  };

  return (
    <section className="login_signup_page">
      <Container className="h-100">
        <Row>
          <Col lg={12} md={12}>
            <span className="logo_light">
              <img
                onClick={() => navigate("/")}
                src={brand_logo}
                alt="brand-logo"
              />
            </span>
          </Col>
        </Row>
        <Row className="h-100">
          <Col
            xl={5}
            lg={6}
            md={9}
            sm={12}
            className="mx-auto login_signup_content_left"
          >
            <h1>Welcome to AceMe6</h1>
            <p>
              Experience AceMed's Success Symphony! Accelerate your journey with
              our Guaranteed Notes and Flashcards—excel in style!
            </p>
          </Col>
          <Col
            xl={5}
            lg={6}
            md={9}
            sm={12}
            className="mx-auto login_signup_content_right"
          >
            <h3>Let’s get started</h3>

            <p style={{ color: "#990d0e" }}>Forgot Password</p>

            <>
              <form onSubmit={(e) => handleSendEmail(e)}>
                <div className="form-group my-3">
                  <label className="mb-2">Email</label>
                  <input
                    type="email"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    placeholder="Enter Your Registerd Email"
                    required
                  />
                </div>

                <button
                  className="btn_primary w-100 mt-4"
                  style={{ cursor: "pointer" }}
                  type="submit"
                >
                  Confirm
                </button>
              </form>
            </>
            <button className="btn_primary mt-3" onClick={handleGoBack}>
              Back
            </button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ForgotPassword;
