import axios from "axios";
import { toast } from "react-toastify";
import Msg from "../../components/common/customError/Msg";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const RE_LOGIN_USER_REQUEST = "RE_LOGIN_USER_REQUEST";
export const RE_LOGIN_USER_SUCCESS = "RE_LOGIN_USER_SUCCESS";
export const RE_LOGIN_USER_FAILURE = "RE_LOGIN_USER_FAILURE";

export const LOGIN_USER_VIA_EMAIL_REQUEST = "LOGIN_USER_VIA_EMAIL_REQUEST";
export const LOGIN_USER_VIA_EMAIL_SUCCESS = "LOGIN_USER_VIA_EMAIL_SUCCESS";
export const LOGIN_USER_VIA_EMAIL_FAILURE = "LOGIN_USER_VIA_EMAIL_FAILURE";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";

export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const CHECK_VALIDATION_REQUEST = "CHECK_VALIDATION_REQUEST";
export const CHECK_VALIDATION_FAILURE = "CHECK_VALIDATION_FAILURE";
export const CHECK_VALIDATION_SUCCESS = "CHECK_VALIDATION_SUCCESS"; 

export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS"; 

export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS"; 

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS"; 

export const CANCEL_MEMBERSHIP_REQUEST = "CANCEL_MEMBERSHIP_REQUEST";
export const CANCEL_MEMBERSHIP_FAILURE = "CANCEL_MEMBERSHIP_FAILURE";
export const CANCEL_MEMBERSHIP_SUCCESS = "CANCEL_MEMBERSHIP_SUCCESS";

export const GET_MY_INVOICES_REQUEST = "GET_MY_INVOICES_REQUEST";
export const GET_MY_INVOICES_FAILURE = "GET_MY_INVOICES_FAILURE";
export const GET_MY_INVOICES_SUCCESS = "GET_MY_INVOICES_SUCCESS";

export const loginUser = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_USER_REQUEST });
      const url = `${BASE_URL}/auth/signup/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: LOGIN_USER_SUCCESS, payload: response.data });
      toast.success("Login Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/");
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_USER_FAILURE, payload: error.message });
      navigate("/");
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const logoutUser = (navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGOUT_USER_REQUEST });
      const url = `${BASE_URL}/auth/logout/v1`;
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: LOGOUT_USER_SUCCESS, payload: response.data });
      toast.success("Logout Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/");
      localStorage.removeItem("token");
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGOUT_USER_FAILURE, payload: error.message });
      navigate("/");
    }
  };
};

export const updateUser = (data, file) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_USER_UPDATE" });
      dispatch({ type: UPDATE_USER_PROFILE_REQUEST });
      if (file) {
        const urlPre = `${BASE_URL}/user/upload/profile/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          { sFileName: file.type, sContentType: file.sContentType },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        data.image = sPath;

        axios.put(sUrl, file, {
          headers: {
            "Content-Type": file.sContentType,
          },
        });
      }
      const url = `${BASE_URL}/user/update/user/profile/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_USER_PROFILE_SUCCESS, payload: response.data });
      toast.success("Profile Updated Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_USER_PROFILE_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const getUser = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_REQUEST });
      const url = `${BASE_URL}/user/get/user/details/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_USER_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_USER_FAILURE, payload: error.message });
    }
  };
};

export const registerUser = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REGISTER_USER_REQUEST });
      const url = `${BASE_URL}/auth/register/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: REGISTER_USER_SUCCESS, payload: response.data });
      toast.success("Register Successfully. Please Login to conitune.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/trail");
    } catch (error) {
      console.error(error);
      dispatch({ type: REGISTER_USER_FAILURE, payload: error.message });
      navigate("/login");
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const loginUserViaEmail = (data, handleSubscription) => {
  return async (dispatch) => {
    const { email } = data;
    try {
      dispatch({ type: LOGIN_USER_VIA_EMAIL_REQUEST });
      const url = `${BASE_URL}/auth/login/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: LOGIN_USER_VIA_EMAIL_SUCCESS, payload: response.data });
      handleSubscription(response.data.data);
      toast.success("Login Successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_USER_VIA_EMAIL_FAILURE, payload: error.message });
      error?.response?.data?.message === "Max Device Attempt"
        ? toast(<Msg email={email} />, {
            type: "error",
            position: "top-right",
            autoClose: 500000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "custom_width",
          })
        : toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
    }
  };
};

export const forgotPassword = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });
      const url = `${BASE_URL}/auth/forgot/password/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: response.data });
      toast.success("Link Send Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: FORGOT_PASSWORD_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const resetPassword = (data, resettoken, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RESET_PASSWORD_REQUEST });
      const url = `${BASE_URL}/auth/reset/password/${resettoken}/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
      toast.success("Password Reset Successfully. Please Login To Continue!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/login");
    } catch (error) {
      console.error(error);
      dispatch({ type: RESET_PASSWORD_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const reLoginUser = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RE_LOGIN_USER_REQUEST });
      const url = `${BASE_URL}/auth/logout/from/all/device/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: RE_LOGIN_USER_SUCCESS, payload: response.data });
      toast.success("Login Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/");
    } catch (error) {
      console.error(error);
      dispatch({ type: RE_LOGIN_USER_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const checkValidation = (data, handleOtpShow) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHECK_VALIDATION_REQUEST });
      const url = `${BASE_URL}/auth/check/validation/of/user/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: CHECK_VALIDATION_SUCCESS, payload: response.data });
      handleOtpShow(data.email);
    } catch (error) {
      dispatch({ type: CHECK_VALIDATION_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const sendOtp = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SEND_OTP_REQUEST });
      const url = `${BASE_URL}/auth/send/otp/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: SEND_OTP_SUCCESS, payload: response.data });
      toast.success("Send OTP Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      dispatch({ type: SEND_OTP_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const reSendOtp = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RESEND_OTP_REQUEST });
      const url = `${BASE_URL}/auth/resend/otp/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: RESEND_OTP_SUCCESS, payload: response.data });
      toast.success("Resend OTP Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      dispatch({ type: RESEND_OTP_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const verifyOtp = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VERIFY_OTP_REQUEST });
      const url = `${BASE_URL}/auth/verify/otp/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: VERIFY_OTP_SUCCESS, payload: response.data });
      toast.success("Verify OTP Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      dispatch({ type: VERIFY_OTP_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const cancelSubscription = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: CANCEL_MEMBERSHIP_REQUEST });
      const url = `${BASE_URL}/plan/cancel/subscription/v1`;
      const response = await axios.post(url,{}, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CANCEL_MEMBERSHIP_SUCCESS, payload: response.data });
      toast.success(response.data.message)
    } catch (error) {
      dispatch({ type: CANCEL_MEMBERSHIP_FAILURE, payload: error.message });
    }
  };
};

export const getMyInvoices = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MY_INVOICES_REQUEST });
      const url = `${BASE_URL}/user//get/invoices/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_MY_INVOICES_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_MY_INVOICES_FAILURE, payload: error.message });
    }
  };
};