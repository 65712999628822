import * as subscriberAction from "./subscriberAction";

export const subscriberFeatureKey = "subscriberInfo";

const initialState = {
  loading: false,
  errorMsg: "",
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case subscriberAction.CREATE_SUBSCRIBER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case subscriberAction.CREATE_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case subscriberAction.CREATE_SUBSCRIBER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
