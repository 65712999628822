import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import brand_logo from "../../assets/svg/brand_logo.svg";

const FreePage = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };
  
  return (
    <section className="login_signup_page">
      {/* <img src={home2}/> */}
      <Container className="h-100">
        <Row>
          <Col lg={12} md={12}>
            <span className="logo_light">
              <img
                onClick={() => navigate("/")}
                src={brand_logo}
                alt="brand-logo"
              />
            </span>
          </Col>
        </Row>
        <Row className="h-100" style={{ minHeight: "70vh" }}>
          <Col
            xl={5}
            lg={6}
            md={9}
            sm={12}
            className="mx-auto login_signup_content_left"
          >
            <h1>Welcome to AceMe6</h1>
            <p>
              Experience AceMed's Success Symphony! Accelerate your journey with
              our Guaranteed Notes and Flashcards—excel in style!
            </p>
          </Col>
          <Col
            xl={5}
            lg={6}
            md={9}
            sm={12}
            className="mx-auto login_signup_content_right"
          >
            <h3>Let’s get started</h3>

            <p style={{ color: "#990d0e" }}>
              <h5>Congratulations on registering! </h5> You now have access to
              our premium features for free for the next 7 days.
            </p>

            <button
              className="btn_primary mt-3 mx-auto"
              onClick={() => handleLogin()}
            >
              Login To Continue
            </button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FreePage;
