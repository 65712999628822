import * as notesAction from "./notesAction";

export const notesFeatureKey = "notesInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  notes: [],
  flashCards: [],
  mcqCards: [],
  videos: [],
  isNotesUpdateStatus: false,
  isFlashCardUpdateStatus: false,
  isVideosUpdateStatus: false,
  isTopicUpdate: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // get notes pdf
    case notesAction.GET_TOPIC_NOTES_REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case notesAction.GET_TOPIC_NOTES_SUCCESS:
      return {
        ...state,
        // loading: false,
        notes: payload.data?.[0]?.notesDetails,
      };
    case notesAction.GET_TOPIC_NOTES_FAILURE:
      return {
        ...state,
        // loading: false,
        errorMsg: payload,
        notes: [],
      };

    // get flash cards

    case notesAction.GET_FLASH_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case notesAction.GET_FLASH_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        flashCards: payload.data?.[0]?.flashCardDetails,
      };
    case notesAction.GET_FLASH_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        flashCards: [],
      };


    // get mcq cards

    case notesAction.GET_MCQ_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case notesAction.GET_MCQ_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        mcqCards: payload.data?.[0]?.mcqDetails,
      };
    case notesAction.GET_MCQ_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        mcqCards: [],
      };

    // update read status of pdf's

    case notesAction.UPDATE_READ_STATUS_REQUEST:
      return {
        ...state,
        // loading: true,
      };
    case notesAction.UPDATE_READ_STATUS_SUCCESS:
      return {
        ...state,
        isTopicUpdate: true,
        // loading: false,
        // notes: payload.data,
        // isNotesUpdateStatus: true,
      };
    case notesAction.UPDATE_READ_STATUS_FAILURE:
      return {
        ...state,
        // loading: false,
        errorMsg: payload,
      };
    case "IS_NOTES_UPDATE":
      return {
        ...state,
        isNotesUpdateStatus: false,
      };

    case "IS_TOPIC_UPDATE":
      return {
        ...state,
        isTopicUpdate: false,
      };

    // update opening status of flash cards

    case notesAction.UPDATE_OPEN_STATUS_CARDS_REQUEST:
      return {
        ...state,
      };
    case notesAction.UPDATE_OPEN_STATUS_CARDS_SUCCESS:
      return {
        ...state,
        isTopicUpdate: true,
      };
    case notesAction.UPDATE_OPEN_STATUS_CARDS_FAILURE:
      return {
        ...state,
        errorMsg: payload,
      };
    case "IS_FLASH_CARD_UPDATE":
      return {
        isFlashCardUpdateStatus: false,
      };

    // update progress count for flashCards
    case notesAction.UPDATE_FLASH_CARDS_PROGRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case notesAction.UPDATE_FLASH_CARDS_PROGRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        isFlashCardUpdateStatus: true,
      };
    case notesAction.UPDATE_FLASH_CARDS_PROGRESS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get videos

    case notesAction.GET_VIDEOS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case notesAction.GET_VIDEOS_SUCCESS:
      return {
        ...state,
        loading: false,
        videos: payload.data?.[0]?.videoDetails,
      };
    case notesAction.GET_VIDEOS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        videos: [],
      };

    // update completed Status

    case notesAction.UPDATE_COMPLETED_VIDEO_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case notesAction.UPDATE_COMPLETED_VIDEO_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isTopicUpdate: true,
      };
    case notesAction.UPDATE_COMPLETED_VIDEO_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case "IS_VIDEO_UPDATE":
      return {
        ...state,
        isVideosUpdateStatus: false,
      };
    default:
      return state;
  }
};
