import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as userAction from "../../../redux/user/userAction"
import { useNavigate } from "react-router-dom";


const Msg = ({ email }) => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
 
  const handleContinueButton = () => {
   dispatch(userAction.reLoginUser({email},navigate))
  };

  return (
    <div className="container">
      <p>You are Already Logged in with Two Devices.</p>
      <p>
        Do you want to Logout from all Devices and login from the current
        device?
      </p>
      <Row>
        <Col md={6} sm={6} xs={6}>
          <button
            className="btn_primary_xs w-100 mt-3"
            onClick={handleContinueButton}
            style={{ fontSize: "14px" }}
          >
            Continue
          </button>
        </Col>
        <Col md={6} sm={6} xs={6}>
          <button
            className="btn_primary_outline_xs w-100 mt-3"
            style={{ fontSize: "14px" }}
          >
            Not Now!
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default Msg;
