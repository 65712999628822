import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const CREATE_SUBSCRIBER_REQUEST = "CREATE_SUBSCRIBER_REQUEST";
export const CREATE_SUBSCRIBER_SUCCESS = "CREATE_SUBSCRIBER_SUCCESS";
export const CREATE_SUBSCRIBER_FAILURE = "CREATE_SUBSCRIBER_FAILURE";

export const createSubscriber = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_SUBSCRIBER_REQUEST });
      const url = `${BASE_URL}/subscriber/create/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: CREATE_SUBSCRIBER_SUCCESS, payload: response.data });
      toast.success(response.data.message);
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_SUBSCRIBER_FAILURE, payload: error.message });
    }
  };
};
